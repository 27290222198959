import { ChipsContainer, CustomChip, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SoftSchedule } from 'lux/models/anonymousOnboarding';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'index.onboarding.title.schedule',
    schedule: {
        fullDay: 'search.schedule.fullDay',
        remote: 'search.schedule.remote',
        shift: 'search.schedule.shift',
        flexible: 'search.schedule.flexible',
        flyInFlyOut: 'search.schedule.flyInFlyOut',
        partTime: 'cluster.partTime',
    },
};

interface SchedulesChipsProps {
    selectedSchedules: SoftSchedule[];
    setSelectedSchedules: (schedules: SoftSchedule[]) => void;
    isMobile?: boolean;
}

const SchedulesChips: TranslatedComponent<SchedulesChipsProps> = ({
    selectedSchedules,
    setSelectedSchedules,
    isMobile,
    trls,
}) => {
    const anonymousOnboarding = useSelector(({ anonymousOnboarding }) => anonymousOnboarding);

    if (!anonymousOnboarding.isSoft) {
        return null;
    }

    return (
        <VSpacingContainer default={12}>
            <Text style={isMobile ? 'secondary' : 'contrast'}>{trls[TrlKeys.title]}</Text>
            <ChipsContainer scrollable={isMobile} correction={15}>
                {anonymousOnboarding.schedules.map((name) => (
                    <CustomChip
                        key={name}
                        size="small"
                        checked={selectedSchedules.includes(name)}
                        onClick={() => {
                            if (selectedSchedules.includes(name)) {
                                setSelectedSchedules(selectedSchedules.filter((item) => item !== name));
                            } else {
                                setSelectedSchedules([...selectedSchedules, name]);
                            }
                        }}
                        style={isMobile ? 'neutral' : 'accent'}
                    >
                        {trls[TrlKeys.schedule[name]]}
                    </CustomChip>
                ))}
            </ChipsContainer>
            {selectedSchedules.length > 0 &&
                selectedSchedules.map((schedule) => {
                    if (schedule === 'partTime') {
                        return anonymousOnboarding.partTime.map((value) => {
                            return <input key={value} type="hidden" name="part_time" value={value} />;
                        });
                    }
                    return <input key={schedule} type="hidden" name="schedule" value={schedule} />;
                })}
        </VSpacingContainer>
    );
};

export default translation(SchedulesChips);

import { ReactNode, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import ConversionNumber from 'bloko/blocks/conversion';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { openAnonymousSignUpModal } from 'lux/models/anonymousSignUp';
import { useMainPageSignupEnable } from 'lux/models/anonymousSignUp/hooks';
import { CurrencyType } from 'lux/models/currencies.types';
import Compensation from 'src/components/Compensation';
import RainbowTileDrop from 'src/components/RainbowCatalog/Drop';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import RainbowTileMobile from 'src/components/RainbowCatalog/Tile/Mobile';

export const COLORS_COUNT = 6;

interface RainbowTileProps {
    professionIndex?: number;
    promo?: boolean;
    title: string;
    vacanciesCount: number;
    tileIndex: number;
    activeTileIndex: number;
    activeDropIndex: number;
    activeTileContent: ReactNode;
    onClick: (index: number, content: ReactNode) => void;
    previousActiveTileIndex: number;
    dataQa: { tile: string };
    onClickMobile?: (title: string, body: ReactNode) => void;
    mobileContent?: ReactNode;
    compensation?: { from: number; to: number; currencyCode: CurrencyType };
    isSuitableVacanciesMode?: boolean;
    isXsOnly: boolean;
    isLoading?: boolean;
    hideMobile?: boolean;
    link?: string;
    pageAnalyticsValue?: string;
    children?: ReactNode;
    showSeoLinksForBots?: boolean;
}

const TrlKeys = {
    vacancies: {
        one: 'professions.vacancies.one',
        some: 'professions.vacancies.some',
        many: 'professions.vacancies.many',
    },
};

const RainbowTile: TranslatedComponent<RainbowTileProps> = ({
    professionIndex,
    promo,
    title,
    compensation,
    vacanciesCount,
    tileIndex,
    activeTileIndex,
    activeDropIndex,
    activeTileContent,
    isSuitableVacanciesMode,
    previousActiveTileIndex,
    dataQa,
    onClick,
    onClickMobile,
    mobileContent,
    isXsOnly,
    link,
    isLoading,
    hideMobile,
    pageAnalyticsValue,
    children,
    showSeoLinksForBots,
    trls,
}) => {
    const mainPageSignUpEnable = useMainPageSignupEnable();
    const dispatch = useDispatch();
    const columnSize = isSuitableVacanciesMode ? '3' : '4';
    const colorIndex = professionIndex !== undefined ? professionIndex % COLORS_COUNT : undefined;
    const userType = useSelector((state) => state.userType);

    const getVacanciesCountTrl = (value: number) => {
        return (
            <ConversionNumber
                one={trls[TrlKeys.vacancies.one]}
                some={trls[TrlKeys.vacancies.some]}
                many={trls[TrlKeys.vacancies.many]}
                zero={NON_BREAKING_SPACE}
                hasValue={true}
                value={value}
            />
        );
    };

    const handleClick = () => {
        Analytics.sendEvent(userType, 'profession_block', title);
        if (!link) {
            onClick(tileIndex, children);
        }
    };

    const handleClickMobile = (title: string, event: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
        Analytics.sendEvent(userType, 'profession_block', title);
        if (link) {
            if (mainPageSignUpEnable) {
                event.preventDefault();
                dispatch(openAnonymousSignUpModal(link));
            }
        } else {
            onClickMobile?.(title, mobileContent);
        }
    };

    const renderLink = (content: ReactNode) => {
        if (link) {
            return (
                <Link
                    to={link}
                    className="dashboard-tiles-item-link"
                    onClick={(event) => {
                        if (mainPageSignUpEnable) {
                            event.preventDefault();
                            dispatch(openAnonymousSignUpModal(link));
                        }
                    }}
                >
                    {content}
                </Link>
            );
        }
        return content;
    };

    const renderTitleWrapper = (content: string) => {
        if (isSuitableVacanciesMode) {
            return <BlokoLink appearance={LinkAppearance.Pseudo}>{content}</BlokoLink>;
        }
        return content;
    };

    return (
        <>
            <div
                className={classnames('dashboard-tiles-item', {
                    'dashboard-tiles-item_active': tileIndex === activeTileIndex,
                    'dashboard-tiles-item_mobile-only': isXsOnly,
                    'dashboard-tiles-item_suitable-vacancies': isSuitableVacanciesMode,
                })}
                data-page-analytics-value={pageAnalyticsValue || title}
                data-rainbow-catalog-tile=""
            >
                {!isXsOnly && (
                    <Column xs="0" s="4" m={columnSize} l={columnSize}>
                        {renderLink(
                            <div className="dashboard-tiles-item-shadow" data-qa={dataQa.tile}>
                                <div
                                    className={classnames('dashboard-tiles-item-inner', {
                                        'dashboard-tiles-item-inner_loading': isLoading,
                                        'dashboard-tiles-item-inner_active': tileIndex === activeTileIndex,
                                    })}
                                    data-page-analytics-event={`vacancy_rainbow_catalog_item.desktop_${title}`}
                                    data-page-analytics-position={professionIndex}
                                    onClick={handleClick}
                                >
                                    <div className="dashboard-tiles-item__layout">
                                        <div
                                            className={classnames('dashboard-tiles-item-highlighter', {
                                                [`dashboard-tiles-item-highlighter_${String(colorIndex)}`]:
                                                    colorIndex !== undefined,
                                                'dashboard-tiles-item-highlighter_promo': promo,
                                            })}
                                        />
                                        <div className="dashboard-tiles-item__body">
                                            <div className="dashboard-tiles-item__content">
                                                <div className="dashboard-tiles-item__title">
                                                    {renderTitleWrapper(title)}
                                                </div>
                                                <div className="dashboard-tiles-item__salary">
                                                    {compensation && !!compensation.to && (
                                                        <Compensation
                                                            from={compensation.from}
                                                            to={compensation.to}
                                                            currencyCode={compensation.currencyCode}
                                                            dash
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="dashboard-tiles-item__counter">
                                                {getVacanciesCountTrl(vacanciesCount)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Column>
                )}
                {!hideMobile && (
                    <Column xs="4" s="0" m="0" l="0" container>
                        <RainbowTileMobile
                            colorIndex={colorIndex}
                            link={link}
                            onClick={handleClickMobile}
                            professionIndex={professionIndex || 0}
                        >
                            {title}
                        </RainbowTileMobile>
                    </Column>
                )}
            </div>
            <RainbowTileDrop
                tileIndex={tileIndex}
                activeDropIndex={activeDropIndex}
                activeTileContent={activeTileContent}
                previousActiveTileIndex={previousActiveTileIndex}
                isXsOnly={isXsOnly}
                {...(showSeoLinksForBots ? { seoContent: children } : {})}
            />
        </>
    );
};

export default translation(RainbowTile);

import { Cell, Link as MagritteLink, CellText, Text } from '@hh.ru/magritte-ui';
import { WalletOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { CurrencyType } from 'lux/models/currencies.types';
import paths from 'src/app/routePaths';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    balance: 'index.employer.billing.pfp',
};

const BalancePfp: TranslatedComponent = ({ trls }) => {
    const moneyCodes = useSelector(
        ({ employerIndexPage }) => employerIndexPage.oldEmployer?.billingSummary?.serviceCategories?.moneyCodes
    );
    const currency = useSelector(
        ({ employerIndexPage }) => employerIndexPage.oldEmployer?.employer?.currency || CurrencyType.RUR
    );

    if (!moneyCodes) {
        return null;
    }

    return (
        <Cell
            align="top"
            data-qa="my-events-pfp"
            left={<WalletOutlinedSize24 />}
            right={
                <Text typography="label-2-regular" style="secondary">
                    <FormatMoney currency={currency}>{moneyCodes.count}</FormatMoney>
                </Text>
            }
        >
            <MagritteLink
                Element={Link}
                style="neutral"
                typography="label-2-regular"
                to={`${paths.pricePayForPerformance}?hhtmFromLabel=my-events`}
            >
                <CellText>{trls[TrlKeys.balance]}</CellText>
            </MagritteLink>
        </Cell>
    );
};

export default translation(BalancePfp);

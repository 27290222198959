import { VSpacing } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import { EmployerState } from 'lux/models/employerStatus';
import { useSelector } from 'src/hooks/useSelector';

import CompanyConfirmation from 'src/components/IndexPageNewEmployer/components/CompanyConfirmation';
import ContactPhones from 'src/components/IndexPageNewEmployer/components/ContactPhones';
import DashboardVacancy from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/DashboardVacancy';

import styles from './index-page-new-employer.less';

const IndexNewEmployer = () => {
    const { category, state } = useSelector(({ employerStatus }) => employerStatus);
    const isEmployerStatusNew = state === EmployerState.New;

    return (
        <ColumnsWrapper>
            <div className={styles.wrapper}>
                <Column xs="4" s="8" m="12" l="16">
                    <DashboardVacancy />
                    <VSpacing default={40} />
                    <div className={styles.support}>
                        <ContactPhones />
                        {isEmployerStatusNew && <CompanyConfirmation companyCategory={category} />}
                    </div>
                    <VSpacing default={72} />
                </Column>
            </div>
        </ColumnsWrapper>
    );
};

export default IndexNewEmployer;

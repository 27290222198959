import { useRef, useState } from 'react';

import { Cell, CellText, Link, Text, Drop, BottomSheet, useBreakpoint } from '@hh.ru/magritte-ui';
import { GridOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { ServiceEmployerDescription } from 'lux/models/oldEmployer/employerBillingSummary';
import { ServiceItemCode } from 'lux/models/price/productCodes';
import paths from 'src/app/routePaths';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import BalanceOptionsTooltipItem from 'src/components/IndexPageEmployer/components/EmployerEventList/BalanceOptions/BalanceOptionsTooltipItem';

const TrlKeys = {
    buy: 'index.employer.billing.buy',
    title: 'balance.option.title',
};

const BalanceOptions: TranslatedComponent = ({ trls }) => {
    const activeOptionInfo = useSelector(
        (state) => state.employerIndexPage.oldEmployer?.billingSummary?.serviceCategories?.optionCodes
    );
    const serviceDescription = useSelector((state) => state.employerIndexPage.oldEmployer?.employer.serviceDescription);

    const [dropOpen, setDropOpen] = useState(false);
    const { isMobile } = useBreakpoint();
    const rootRef = useRef(null);

    if (activeOptionInfo?.count && activeOptionInfo?.count > 0 && serviceDescription) {
        const serviceDescriptionOption = serviceDescription.filter(
            (service: ServiceEmployerDescription) => service.code === ServiceItemCode.OptionPremium
        );

        const content = (
            <div className="purchased-services">
                {serviceDescriptionOption.map((service: ServiceEmployerDescription, index: number) => (
                    <BalanceOptionsTooltipItem service={service} key={index} />
                ))}
            </div>
        );

        return (
            <div ref={rootRef}>
                <Cell
                    data-qa="my-events-options"
                    left={<GridOutlinedSize24 />}
                    right={
                        <Text typography="label-2-regular" style="secondary">
                            {activeOptionInfo.count}
                        </Text>
                    }
                    onClick={() => {
                        setDropOpen((open) => !open);
                    }}
                >
                    <a data-qa="my-events-options-name">
                        <CellText>{trls[TrlKeys.title]}</CellText>
                    </a>
                </Cell>

                <Drop
                    activatorRef={rootRef}
                    placement="right-top"
                    visible={dropOpen}
                    onClose={() => {
                        setDropOpen(false);
                    }}
                >
                    {content}
                </Drop>

                <BottomSheet
                    visible={isMobile && dropOpen}
                    onClose={() => {
                        setDropOpen(false);
                    }}
                >
                    {content}
                </BottomSheet>
            </div>
        );
    }

    return (
        <Cell
            data-qa="my-events-options"
            right={
                <Link href={paths.priceZpPromotion} data-qa="my-events-option-access-buy">
                    {trls[TrlKeys.buy]}
                </Link>
            }
            left={<GridOutlinedSize24 />}
        >
            <a href={paths.priceZpPromotion} data-qa="my-events-option-access-name">
                <CellText>{trls[TrlKeys.title]}</CellText>
            </a>
        </Cell>
    );
};

export default translation(BalanceOptions);

import { useCallback, useRef, useState } from 'react';

import { Button, HSpacingContainer, Title, useBreakpoint, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SoftSchedule, SnatchSchedule } from 'lux/models/anonymousOnboarding';
import { SupernovaSearchName } from 'lux/models/supernovaSearchName';
import Form from 'src/components/Form';
import AdvancedSearchButton from 'src/components/SupernovaSearch/AdvancedSearchButton';
import SearchSuggest from 'src/components/SupernovaSearch/MagritteSearchSuggest';
import useSearchNavItemProps from 'src/components/SupernovaSearch/useSearchNavItemProps';
import useSendFormSubmitEvent from 'src/components/SupernovaSearch/useSendFormSubmitEvent';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import SchedulesChips from 'src/components/IndexPageAnonymous/Onboarding/SchedulesChips';
import SchedulesSelect from 'src/components/IndexPageAnonymous/Onboarding/SchedulesSelect';
import ShortRolesChips from 'src/components/IndexPageAnonymous/Onboarding/ShortRolesChips';
import ShortRolesSelect from 'src/components/IndexPageAnonymous/Onboarding/ShortRolesSelect';
import {
    getShortRolesText,
    getOnboardingLabel,
    isCompositedRole,
    DUMMY_SHORT_ROLE_ID,
} from 'src/components/IndexPageAnonymous/Onboarding/utils';

import styles from './styles.less';

const ONBOARDING_SEARCH_FORM_ID = 'onboarding_search_form';

const TrlKeys = {
    header: 'index.headers.main.onboarding',
    button: {
        find: 'index.onboarding.button.find',
        show: 'index.onboarding.button.show',
    },
};

// TODO: перевести на useBreakpoint, а в VSpacing добавить gteM, когда баннер переедет на магритт,
//  сейчас XS отличается от S, тк его основной контейнер еще на блоко,
//  поэтому передаю проп из компонента, отвечающего за отображение на мобилке
interface OnboardingProps {
    isMobile?: boolean;
}

const Onboarding: TranslatedComponent<OnboardingProps> = ({ isMobile, trls }) => {
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const criteriaTextUpdated = useSelector(({ criteriaTextUpdated }) => criteriaTextUpdated);
    const anonymousOnboarding = useSelector(({ anonymousOnboarding }) => anonymousOnboarding);
    const { isSoft, isSnatch } = anonymousOnboarding;

    const formRef = useRef<HTMLFormElement>(null);

    const [selectedShortRoles, setSelectedShortRoles] = useState<string[]>([]);
    const [selectedSchedules, setSelectedSchedules] = useState<SoftSchedule[] | SnatchSchedule[]>([]);

    const searchProps = useSearchNavItemProps(SupernovaSearchName.Vacancies);
    const sendFormSubmitEvent = useSendFormSubmitEvent(SupernovaSearchName.Vacancies, true);

    const { isS } = useBreakpoint();

    const onFormSubmit = useCallback(() => {
        sendFormSubmitEvent();
        formRef.current?.requestSubmit();
    }, [sendFormSubmitEvent]);

    if (!searchProps || (!isSoft && !isSnatch)) {
        return null;
    }

    return (
        <Form
            id={ONBOARDING_SEARCH_FORM_ID}
            onSubmit={sendFormSubmitEvent}
            action={searchProps.action}
            ref={formRef}
            method="GET"
        >
            <Title
                Element="h1"
                alignment={isSoft && !isMobile ? 'center' : 'left'}
                style={isMobile ? 'primary' : 'contrast'}
                size={isMobile ? 'medium' : 'extra-large'}
            >
                {trls[TrlKeys.header]}
            </Title>
            <VSpacing default={isMobile ? 24 : 40} />
            {isSoft && (
                <VSpacingContainer default={24}>
                    <HSpacingContainer default={isMobile ? 16 : 24}>
                        <SearchSuggest
                            searchName={SupernovaSearchName.Vacancies}
                            isDashboardMobileSearch={isMobile}
                            submitForm={onFormSubmit}
                            beforeUpdateText={() => setSelectedShortRoles([])}
                        />
                        <AdvancedSearchButton searchName={SupernovaSearchName.Vacancies} isMagritte shortView />
                    </HSpacingContainer>
                    <ShortRolesChips
                        selectedShortRoles={selectedShortRoles}
                        setSelectedShortRoles={setSelectedShortRoles}
                        isMobile={isMobile}
                    />
                    <SchedulesChips
                        selectedSchedules={selectedSchedules as SoftSchedule[]}
                        setSelectedSchedules={setSelectedSchedules}
                        isMobile={isMobile}
                    />
                    <Button
                        mode="primary"
                        style="accent"
                        type="submit"
                        disabled={!criteriaTextUpdated && selectedShortRoles.length === 0}
                    >
                        {trls[TrlKeys.button.find]}
                    </Button>
                </VSpacingContainer>
            )}
            {isSnatch && (
                <VSpacingContainer default={12}>
                    {/* isMobile пробрасывается через пропы см. строку 38 и пока, что отвечает за XS,
                    но на S в любом случае нужно разместить селекты друг под другом */}
                    {isMobile || isS ? (
                        <VSpacingContainer default={12} gteXs={8}>
                            <ShortRolesSelect
                                selectedShortRoles={selectedShortRoles}
                                setSelectedShortRoles={setSelectedShortRoles}
                            />
                            <SchedulesSelect
                                selectedSchedules={selectedSchedules as SnatchSchedule[]}
                                setSelectedSchedules={setSelectedSchedules}
                            />
                        </VSpacingContainer>
                    ) : (
                        <HSpacingContainer default={12}>
                            <div className={styles.shortRoleSelect}>
                                <ShortRolesSelect
                                    selectedShortRoles={selectedShortRoles}
                                    setSelectedShortRoles={setSelectedShortRoles}
                                />
                            </div>
                            <div className={styles.scheduleSelect}>
                                <SchedulesSelect
                                    selectedSchedules={selectedSchedules as SnatchSchedule[]}
                                    setSelectedSchedules={setSelectedSchedules}
                                />
                            </div>
                        </HSpacingContainer>
                    )}
                    <Button mode="primary" style="accent" type="submit" disabled={selectedShortRoles.length === 0}>
                        {trls[TrlKeys.button.show]}
                    </Button>
                </VSpacingContainer>
            )}
            {isSnatch && selectedShortRoles.length > 0 && (
                <input
                    type="hidden"
                    name="text"
                    value={getShortRolesText(selectedShortRoles, anonymousOnboarding.dictShortRoles)}
                />
            )}
            {selectedShortRoles.length === 1 &&
                selectedShortRoles[0] !== DUMMY_SHORT_ROLE_ID &&
                !isCompositedRole(anonymousOnboarding.dictShortRoles[selectedShortRoles[0]]) && (
                    <input type="hidden" name="needShortRoleExtensions" value="true" />
                )}
            {selectedShortRoles.length > 0 && selectedSchedules.length === 0 && (
                <input type="hidden" name="needSchedulesChips" value={isSoft ? 'partTime' : 'doesNotMatter'} />
            )}
            <input type="hidden" name="area" value={anonymousOnboarding.area} />
            <input type="hidden" name="hhtmFrom" value={hhtmSource} />
            <input
                type="hidden"
                name="hhtmFromLabel"
                value={getOnboardingLabel(selectedShortRoles, selectedSchedules)}
            />
            <input type="hidden" name="no_magic" value="true" />
        </Form>
    );
};

export default translation(Onboarding);

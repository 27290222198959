import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind, ButtonScale, ButtonType } from 'bloko/blocks/button';
import HSpacing from 'bloko/blocks/hSpacing';
import BlokoLink from 'bloko/blocks/link';
import Modal, { ModalContent, ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { updateRsvUserCompetenciesModal } from 'lux/models/rsvUserCompetencies';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './rsv-user-competencies.less';

const TrlKeys = {
    title: 'rsvUserCompetenciesModal.title',
    content: 'rsvUserCompetenciesModal.content',
    close: 'rsvUserCompetenciesModal.close',
    add: 'rsvUserCompetenciesModal.add',
};

const RsvUserCompetenciesModal: TranslatedComponent = ({ trls }): JSX.Element => {
    const hhidAccount = useSelector(({ hhidAccount }) => hhidAccount);
    const dispatch = useDispatch();
    const elementRef = useRef(null);
    const handleCloseModal = () => {
        Analytics.sendHHEventButtonClick('rsv_user_competencies_close');
        dispatch(updateRsvUserCompetenciesModal(false));
        dispatch(push('/'));
    };

    useEffect(() => {
        if (elementRef.current) {
            Analytics.sendHHEvent('element_shown', {
                elementName: 'rsv_user_competencies_modal',
            });
        }
    }, []);

    return (
        <div ref={elementRef}>
            <Modal
                closeButtonShow={false}
                visible={true}
                backgroundClick={false}
                onClose={handleCloseModal}
                style={{ width: '680px' }}
            >
                <ModalHeader>
                    <ModalTitle>
                        {hhidAccount ? (
                            <>
                                <span>{`${hhidAccount.firstName} `}</span>
                                {hhidAccount.middleName && <span>{`${hhidAccount.middleName} `}</span>}
                                <span>{`${hhidAccount.lastName}, `}</span>
                                <span>{trls[TrlKeys.title]}</span>
                            </>
                        ) : (
                            <span className={styles.rsvUserCompetenciesModalTitle}>{trls[TrlKeys.title]}</span>
                        )}
                    </ModalTitle>
                </ModalHeader>
                <ModalContent>
                    <Text size={TextSize.Large}>{trls[TrlKeys.content]}</Text>
                </ModalContent>
                <ModalFooter className={styles.rsvUserCompetenciesModalFooter}>
                    <BlokoLink>
                        <Button type={ButtonType.Button} scale={ButtonScale.Large} onClick={handleCloseModal}>
                            {trls[TrlKeys.close]}
                        </Button>
                    </BlokoLink>
                    <HSpacing base={2} />
                    <BlokoLink>
                        <Button
                            Element={Link}
                            to="/rsv_user_competencies/check_token"
                            kind={ButtonKind.Primary}
                            scale={ButtonScale.Large}
                            appearance={ButtonAppearance.Filled}
                            onClick={() => Analytics.sendHHEventButtonClick('rsv_user_competencies_add')}
                        >
                            {trls[TrlKeys.add]}
                        </Button>
                    </BlokoLink>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default translation(RsvUserCompetenciesModal);

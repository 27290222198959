import { useRef } from 'react';

import { Avatar, Card, Cell, CellText, TooltipHover } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';

import { CompanyOfTheDay } from 'lux/models/companiesOfTheDay';
import translation from 'src/components/translation';
import useIsTextClamped from 'src/hooks/useIsTextClamped';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { WorkInCompanyLocation } from 'src/utils/sendAdvSpyAnalytics';

import { getCompanyLink, sendAnalytics } from 'src/components/WorkInCompany/Utils';

const TrlKeys = {
    vacancies: {
        one: 'main.counter.vacancies.one',
        some: 'main.counter.vacancies.some',
        many: 'main.counter.vacancies.many',
    },
};

interface CompanyItemProps {
    company: CompanyOfTheDay;
}

const CompanyItem: TranslatedComponent<CompanyItemProps> = ({ company, trls }) => {
    const companyNameRef = useRef(null);
    const isNameClamped = useIsTextClamped(companyNameRef);
    const locale = useSelector((state) => state.locale);
    const isZP = useIsZarplataPlatform();

    return (
        <Card
            Element={Link}
            to={getCompanyLink(company, locale, WorkInCompanyLocation.MainPageBottom, isZP)}
            showBorder
            padding={16}
            borderRadius={16}
            stretched
            verticalStretched
            actionCard
            onClick={() => sendAnalytics(company, 'employer_of_the_day')}
        >
            <Cell
                left={
                    <Avatar
                        shapeCircle
                        size={40}
                        mode="image"
                        fallbackMode="placeholder"
                        image={company.logoUrl || ''}
                        placeholder="city"
                        aria-label=""
                    />
                }
            >
                <CellText maxLines={1}>
                    <div ref={companyNameRef}>{company.companyName}</div>
                    {isNameClamped && (
                        <TooltipHover placement="top-center" activatorRef={companyNameRef}>
                            {company.companyName}
                        </TooltipHover>
                    )}
                </CellText>
                <CellText type="subtitle">
                    <ConversionNumber
                        value={company.vacanciesNumber}
                        formatValue={(value) => NumberFormatter.format(String(value))}
                        one={trls[TrlKeys.vacancies.one]}
                        some={trls[TrlKeys.vacancies.some]}
                        many={trls[TrlKeys.vacancies.many]}
                    />
                </CellText>
            </Cell>
        </Card>
    );
};

export default translation(CompanyItem);

import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import Conversion from 'bloko/blocks/conversion';
import { H2 } from 'bloko/blocks/header';
import Modal, { ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { Bound } from 'bloko/common/elementSpy';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { CompanyOfTheDay } from 'lux/models/companiesOfTheDay';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';
import { WorkInCompanyLocation } from 'src/utils/sendAdvSpyAnalytics';

import MobileCompanyItem from 'src/components/WorkInCompany/MobileCompanyItem';
import { getCompanyLink, sendAnalytics } from 'src/components/WorkInCompany/Utils';
import WorkInBanner from 'src/components/WorkInCompany/WorkInBanner';
import useCompaniesOfTheDayViewAnalytics from 'src/components/WorkInCompany/hooks/useCompaniesOfTheDayViewAnalytics';

const TrlKeys = {
    title: 'professions.promo.employers',
    vacancyOne: 'main.counter.vacancies.one',
    vacancyMany: 'main.counter.vacancies.many',
    vacancySome: 'main.counter.vacancies.some',
    vacancyZero: 'main.counter.vacancies.zero',
};

const trackingParams = { trackingBounds: [Bound.Left] };

const WorkInCompanyMobile: TranslatedComponent = ({ trls }) => {
    const companiesOfTheDay = useSelector((state) => state.companiesOfTheDay);
    const [showModal, toggleShowModal] = useToggleState(false);

    const locale = useSelector((state) => state.locale);

    const isZP = useIsZarplataPlatform();
    const companiesRefs = useCompaniesOfTheDayViewAnalytics(
        companiesOfTheDay,
        WorkInCompanyLocation.MainPageXsSlider,
        trackingParams
    );

    const renderCompany = (company: CompanyOfTheDay) => (
        <div className="index-slider-item" key={company.id}>
            <Link
                to={getCompanyLink(company, locale, WorkInCompanyLocation.MainPageXsSlider, isZP)}
                onClick={() => sendAnalytics(company, 'employer_of_the_day')}
                className="index-slider-employer"
            >
                <span
                    className="index-slider-employer-name"
                    ref={(ref) => {
                        if (ref && companiesRefs.current) {
                            companiesRefs.current[company.id] = ref;
                        }
                    }}
                >
                    <Text Element="span" strong>
                        {(company.logoUrl && (
                            <img
                                className="index-slider-employer-logo"
                                src={company.logoUrl}
                                alt={company.companyName}
                            />
                        )) || <span data-qa="company-of-the-day-name">{company.companyName}</span>}
                    </Text>
                </span>
                <span className="index-slider-employer-vacancies">
                    <Conversion
                        value={company.vacanciesNumber}
                        one={`${trls[TrlKeys.vacancyOne]}`}
                        some={`${trls[TrlKeys.vacancySome]}`}
                        many={`${trls[TrlKeys.vacancyMany]}`}
                        zero={`${trls[TrlKeys.vacancyZero]}`}
                    />
                </span>
            </Link>
        </div>
    );

    return (
        <>
            <Column xs="4">
                <div data-page-analytics-event="employer_of_the_day_list.mobile" onClick={toggleShowModal}>
                    <H2>
                        <div className="index-section-header index-section-header_with-chevron">
                            {trls[TrlKeys.title]}
                        </div>
                        <VSpacing base={4} />
                    </H2>
                </div>
            </Column>
            <Column xs="4" container>
                <div className="index-slider-wrapper">
                    <div className="index-slider">
                        {companiesOfTheDay.map((company: CompanyOfTheDay) => renderCompany(company))}
                    </div>
                </div>
                <Modal visible={showModal} onClose={toggleShowModal}>
                    <ModalHeader outlined>
                        <ModalTitle>{trls[TrlKeys.title]}</ModalTitle>
                    </ModalHeader>
                    <div className="mobile-work-in-company-wrapper">
                        <div className="mobile-work-in-company-banner">
                            <WorkInBanner isMobile />
                        </div>

                        <ul className="mobile-work-in-company-content">
                            {companiesOfTheDay.map((company: CompanyOfTheDay, index: number) => (
                                <MobileCompanyItem
                                    company={company}
                                    isLast={index === companiesOfTheDay.length - 1}
                                    key={company.id}
                                />
                            ))}
                        </ul>
                    </div>
                </Modal>
            </Column>
        </>
    );
};

export default translation(WorkInCompanyMobile);

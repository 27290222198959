import { FC, PropsWithChildren } from 'react';

import { Avatar, Cell, CellText, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import { ArticleItemWithImage } from 'lux/models/article.types';
import useMagritte from 'src/hooks/useMagritte';

const NewsBoxItem: FC<ArticleItemWithImage & PropsWithChildren> = ({ href, image, title, isArticle = false }) => {
    const analyticsType = isArticle ? 'article_item.articleItem' : 'news_item.newsItem';
    const isMagritte = useMagritte();

    if (isMagritte) {
        return (
            <Cell
                left={
                    <Avatar
                        image={image}
                        size={48}
                        mode="image"
                        fallbackMode="placeholder"
                        placeholder="city"
                        aria-label={title}
                    />
                }
            >
                <CellText>
                    <MagritteLink Element={Link} to={href} style="neutral" data-page-analytics-event={analyticsType}>
                        {title}
                    </MagritteLink>
                </CellText>
            </Cell>
        );
    }

    return (
        <li className="news-box-item">
            <Link to={href} data-page-analytics-event={analyticsType}>
                <span className="news-box-item__link">
                    <img loading="lazy" className="news-box-item__image" src={image} />
                    {title}
                </span>
            </Link>
        </li>
    );
};

export default NewsBoxItem;

// tempexp_33243_file

import { FC, useEffect, useRef } from 'react';
import classname from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Button, Card, Title, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';

import TemporaryEmploymentModal from 'src/components/TemporaryEmploymentModal';
import useToggleState from 'src/hooks/useToggleState';

import styles from './temporary-employment.less';

const TemporaryEmployment: FC = () => {
    const { isXS, isL } = useBreakpoint();

    const [modalVisible, toggleModal] = useToggleState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        Analytics.sendHHEventElementShown(containerRef.current, {
            name: 'widget_parttime_job',
        });
    }, []);

    const isNarrowCard = isXS || isL;
    return (
        <div className={styles.temporaryEmployment} ref={containerRef}>
            <Card showBorder padding={24} borderRadius={24} stretched>
                <div className={styles.temporaryEmploymentContentWrapper}>
                    <div className={styles.temporaryEmploymentContent}>
                        <div>
                            <Title
                                size="small"
                                maxLines={2}
                                Element="h2"
                                description="Пока наймите временного — это в 2-3 раза быстрее"
                            >
                                Долго ищете сотрудника в штат?
                            </Title>
                            <VSpacing default={16} />
                        </div>
                        <div>
                            <div
                                className={classname(styles.temporaryEmploymentImage, {
                                    [styles.temporaryEmploymentImageHide]: !isNarrowCard,
                                })}
                            />
                            <Button
                                mode="secondary"
                                style="accent"
                                stretched={isNarrowCard}
                                onClick={() => {
                                    Analytics.sendHHEventButtonClick('widget_parttime_job');
                                    toggleModal();
                                }}
                            >
                                Создать вакансию
                            </Button>
                        </div>
                    </div>
                    <div
                        className={classname(styles.temporaryEmploymentImage, {
                            [styles.temporaryEmploymentImageHide]: isNarrowCard,
                        })}
                    />
                </div>
            </Card>
            <TemporaryEmploymentModal visible={modalVisible} onClose={toggleModal} />
        </div>
    );
};

export default TemporaryEmployment;

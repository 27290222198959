import { useCallback, useMemo, useState } from 'react';

import { Button, Select, SelectOption } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SnatchSchedule } from 'lux/models/anonymousOnboarding';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    placeholder: 'index.onboarding.title.schedule',
    schedule: {
        fullDay: 'search.schedule.fullDay',
        remote: 'search.schedule.remote',
        shift: 'search.schedule.shift',
        flexible: 'search.schedule.flexible',
        flyInFlyOut: 'search.schedule.flyInFlyOut',
        doesNotMatter: 'search.schedule.doesNotMatter',
    },
    save: 'index.onboarding.select.save',
};

const DOES_NOT_MATTER_SCHEDULE = 'doesNotMatter';

interface SchedulesSelectProps {
    selectedSchedules: SnatchSchedule[];
    setSelectedSchedules: (schedules: SnatchSchedule[]) => void;
    isMobile?: boolean;
}

const SchedulesSelect: TranslatedComponent<SchedulesSelectProps> = ({
    selectedSchedules,
    setSelectedSchedules,
    trls,
}) => {
    const anonymousOnboarding = useSelector(({ anonymousOnboarding }) => anonymousOnboarding);

    const [selectKey, changeSelectKey] = useState(0);

    const options: SelectOption[] = useMemo(() => {
        if (anonymousOnboarding.isSnatch) {
            const { schedules } = anonymousOnboarding;
            return schedules.map((value) => ({
                label: trls[TrlKeys.schedule[value]],
                value,
            }));
        }
        return [];
    }, [anonymousOnboarding, trls]);

    const onChange = useCallback(
        (values: string[]) => {
            const schedules = values as SnatchSchedule[];
            if (schedules.includes(DOES_NOT_MATTER_SCHEDULE) && !selectedSchedules.includes(DOES_NOT_MATTER_SCHEDULE)) {
                setSelectedSchedules([DOES_NOT_MATTER_SCHEDULE]);
                changeSelectKey(selectKey + 1);
            } else if (selectedSchedules.includes(DOES_NOT_MATTER_SCHEDULE) && schedules.length > 1) {
                setSelectedSchedules(schedules.filter((item) => item !== DOES_NOT_MATTER_SCHEDULE));
                changeSelectKey(selectKey + 1);
            } else {
                setSelectedSchedules(schedules);
            }
        },
        [selectKey, selectedSchedules, setSelectedSchedules]
    );

    if (!anonymousOnboarding.isSnatch) {
        return null;
    }

    return (
        <>
            <Select
                key={selectKey}
                multiple
                elevatePlaceholder
                bottomSheetHeight="content"
                size="large"
                placeholder={trls[TrlKeys.placeholder]}
                name="schedule"
                options={options}
                value={selectedSchedules}
                onChange={onChange}
                applyChangesButton={
                    <Button mode="primary" style="accent">
                        {trls[TrlKeys.save]}
                    </Button>
                }
            />
            {selectedSchedules.length > 0 &&
                selectedSchedules.map((schedule) => {
                    if (schedule === DOES_NOT_MATTER_SCHEDULE) {
                        return anonymousOnboarding.schedules.map(
                            (value) =>
                                value !== DOES_NOT_MATTER_SCHEDULE && (
                                    <input key={value} type="hidden" name="schedule" value={value} />
                                )
                        );
                    }
                    return <input key={schedule} type="hidden" name="schedule" value={schedule} />;
                })}
        </>
    );
};

export default translation(SchedulesSelect);

import { useRef, RefObject, useEffect } from 'react';

import { VacancyOfTheDay } from 'lux/models/vacanciesOfTheDay';
import { sendAnythingViewAnalytics, VacancyOfTheDayLocation, SpyParams } from 'src/utils/sendAdvSpyAnalytics';

type VacanciesOfTheDay = Pick<VacancyOfTheDay, 'vacancyId' | 'viewUrl'>[];

const initVacanciesRefs = (vacanciesOfTheDay: VacanciesOfTheDay) =>
    vacanciesOfTheDay.reduce((result, { vacancyId }) => {
        result[vacancyId] = null;

        return result;
    }, {} as Record<number, HTMLElement | null>);

type UseVacanciesOfTheDayViewAnalytics = (
    vacanciesOfTheDay: VacanciesOfTheDay,
    location: VacancyOfTheDayLocation,
    spyParams?: SpyParams
) => RefObject<Record<number, HTMLElement | null>>;

const useVacanciesOfTheDayViewAnalytics: UseVacanciesOfTheDayViewAnalytics = (
    vacanciesOfTheDay,
    location,
    spyParams
) => {
    const vacanciesRefs = useRef(initVacanciesRefs(vacanciesOfTheDay));

    useEffect(() => {
        vacanciesOfTheDay.forEach(({ vacancyId, viewUrl }) => {
            const element = vacanciesRefs.current?.[vacancyId];

            if (element) {
                sendAnythingViewAnalytics({
                    element,
                    viewUrl,
                    location,
                    spyParams,
                });
            }
        });
    }, [vacanciesOfTheDay, location, spyParams]);

    return vacanciesRefs;
};

export default useVacanciesOfTheDayViewAnalytics;

import { useEffect, useRef } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Card, Title, Button as MagritteButton, VSpacing as MagritteVSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import { H2 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { UserType } from 'lux/models/userType';
import StaticImg from 'src/components/StaticImg';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import styles from './employer-rating-teaser.less';

const POLL_URL = 'https://rating.hh.ru/poll/?utm_source=hh.ru&utm_medium=referral&utm_campaign=above_footer_poll';

const TrlKeys = {
    header: 'index.employersRatingTeaser.header',
    description: 'index.employersRatingTeaser.description',
    button: 'index.employersRatingTeaser.button',
};

interface EmployerRatingTeaserProps {
    isInline?: boolean;
}

const EmployerRatingTeaser: TranslatedComponent<EmployerRatingTeaserProps> = ({ trls, isInline }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const userType = useSelector((state) => state.userType);
    const isApplicant = userType === UserType.Applicant;
    const { isGtM, isXS } = useBreakpoint();
    const isMagritte = useMagritte();

    const classnamesWithApplicantVariant = (basicClass: string) => {
        return classnames(basicClass, {
            [`${basicClass}_applicant`]: isApplicant,
        });
    };

    const sendClickAnalytics = () => {
        Analytics.sendEvent(userType, 'button_click', 'employers-rating-teaser');
    };

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        Analytics.sendEventElementShown(containerRef.current, userType, 'show', 'employers-rating-teaser');
    }, [userType]);

    if (isMagritte) {
        return (
            <Card padding={12} borderRadius={24} stretched verticalStretched style="extra-5">
                <div className={styles.wrapper} ref={containerRef}>
                    <StaticImg className={styles.image} path="/i/hh/index/employer-rating-teaser.svg" />
                    <MagritteVSpacing default={24} />
                    <div>
                        <Title
                            Element="h3"
                            alignment={isXS ? 'center' : 'left'}
                            size="medium"
                            descriptionStyle="primary"
                            description={trls[TrlKeys.description]}
                        >
                            {trls[TrlKeys.header]}
                        </Title>
                        <MagritteVSpacing default={16} />
                        <MagritteButton
                            size="medium"
                            style="accent"
                            mode="primary"
                            stretched={isXS || isGtM}
                            onClick={sendClickAnalytics}
                        >
                            {trls[TrlKeys.button]}
                        </MagritteButton>
                    </div>
                </div>
            </Card>
        );
    }

    return (
        <div
            className={classnames('employers-rating-teaser-content', {
                'employers-rating-teaser-content_inline': isInline,
            })}
            ref={containerRef}
        >
            <div>
                <div className="employers-rating-teaser-heading-wrapper">
                    <H2 Element="h3">{trls[TrlKeys.header]}</H2>
                </div>
                <div className={classnamesWithApplicantVariant('employers-rating-teaser-description-wrapper')}>
                    <Text>{trls[TrlKeys.description]}</Text>
                </div>
                {!isInline && (
                    <div className={classnamesWithApplicantVariant('employers-rating-teaser-image-wrapper')}>
                        <span className={classnamesWithApplicantVariant('employers-rating-teaser-image')} />
                    </div>
                )}
                <div className="employers-rating-teaser-link-wrapper">
                    <BlokoLink target="_blank" href={POLL_URL} onClick={sendClickAnalytics} disableVisited>
                        <span className="employers-rating-teaser-button-label">{trls[TrlKeys.button]}</span>
                    </BlokoLink>
                </div>
                <div className="employers-rating-teaser-button-wrapper">
                    <Button
                        kind={ButtonKind.Primary}
                        appearance={ButtonAppearance.Outlined}
                        target="_blank"
                        Element="a"
                        href={POLL_URL}
                        onClick={sendClickAnalytics}
                    >
                        <span className={classnamesWithApplicantVariant('employers-rating-teaser-button-label')}>
                            {trls[TrlKeys.button]}
                        </span>
                    </Button>
                </div>
            </div>
            {isInline && (
                <div className="employers-rating-teaser-image-wrapper">
                    <span className="employers-rating-teaser-image" />
                </div>
            )}
        </div>
    );
};

export default translation(EmployerRatingTeaser);

import { UserType } from 'lux/models/userType';
import FullPageLayout from 'src/app/layouts/FullPageLayout';
import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import AdditionalCheck from 'src/components/Employer/AdditionalCheck';
import FreeVpplNotification from 'src/components/FreeVpplNotification';
import FreeVpplOnboardingModal from 'src/components/FreeVpplOnboardingModal';
import IndexAnonymous from 'src/components/IndexPageAnonymous';
import IndexApplicant from 'src/components/IndexPageApplicant';
import IndexEmployer from 'src/components/IndexPageEmployer';
import IndexNewEmployer from 'src/components/IndexPageNewEmployer';
import MobileAppPromotion from 'src/components/MobileAppPromotion';
import { useSelector } from 'src/hooks/useSelector';

const IndexPage = () => {
    const isNewEmployer = useSelector(({ isNewEmployer }) => isNewEmployer);
    const { title } = useSelector((state) => state.pageMetaData);
    const showAdditionalCheck = useSelector((state) => state.additionalCheckFirstView);
    const userType = useSelector((state) => state.userType);

    if (userType === UserType.Applicant) {
        return (
            <FullPageLayout>
                <IndexApplicant />
                <MobileAppPromotion />
            </FullPageLayout>
        );
    }

    if (userType === UserType.Employer) {
        return (
            <PageLayout layout={EmptyLayout} title={title}>
                {showAdditionalCheck && <AdditionalCheck />}
                {isNewEmployer ? <IndexNewEmployer /> : <IndexEmployer />}
                <FreeVpplOnboardingModal />
                <FreeVpplNotification />
            </PageLayout>
        );
    }

    return (
        <FullPageLayout>
            <IndexAnonymous />
        </FullPageLayout>
    );
};

export default IndexPage;

import { ReactNode } from 'react';
import classnames from 'classnames';

import { Breakpoint, Title, useBreakpoint, VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import { H2 } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { BannerPlace } from 'lux/models/banners';
import { CompanyOfTheDay } from 'lux/models/companiesOfTheDay';
import IndexCollapsibleBlock from 'src/components/IndexCollapsibleBlock';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { WorkInCompanyLocation } from 'src/utils/sendAdvSpyAnalytics';

import CompanyItem from 'src/components/WorkInCompany/CompanyItem';
import { getCompanyLink, sendAnalytics } from 'src/components/WorkInCompany/Utils';
import WorkInBanner from 'src/components/WorkInCompany/WorkInBanner';
import useCompaniesOfTheDayViewAnalytics from 'src/components/WorkInCompany/hooks/useCompaniesOfTheDayViewAnalytics';

import styles from './work-in-company.less';

const BELARUS_DOMAIN_AREA_ID = '1002';
enum PartIndex {
    One = 3,
    Two = 13,
    Three = 19,
}
const COMPANIES_PREVIEW_DEFAULT_COUNT = 12;
const companiesPreviewCountForBreakpoint = {
    [Breakpoint.XS]: Infinity,
    [Breakpoint.S]: 7,
    [Breakpoint.M]: 9,
    [Breakpoint.XL]: 12,
};

const TrlKeys = {
    titleMain: 'index.companies.work.in',
    titleTwo: 'index.companies.work.two',
    titleIn: 'wic-vod.in',
};

interface WorkInCompanyProps {
    isApplicant?: boolean;
    spacingBottom?: ReactNode;
}

const WorkInCompany: TranslatedComponent<WorkInCompanyProps> = ({ trls, isApplicant = false, spacingBottom }) => {
    const companiesOfTheDay = useSelector((state) => state.companiesOfTheDay);
    const companiesOfTheDayTrls = useSelector((state) => state.companiesOfTheDayTrls);
    const locale = useSelector((state) => state.locale);
    const isMagritte = useMagritte();
    const { breakpoint, isXS } = useBreakpoint();

    const isZP = useIsZarplataPlatform();
    const companiesRefs = useCompaniesOfTheDayViewAnalytics(companiesOfTheDay, WorkInCompanyLocation.MainPageBottom);

    const workInCompanyBanners = useSelector(
        (state) => state.banners[isXS ? BannerPlace.INDEX_WORK_IN_COMPANY_MOBILE : BannerPlace.INDEX_WORK_IN_COMPANY]
    );
    const isWorkInCompanyBannerExist = !!workInCompanyBanners?.[0];
    let companiesListSliceIndex = 0;
    if (!isXS) {
        companiesListSliceIndex = isWorkInCompanyBannerExist ? 1 : 3;
    }

    const getHeaderText = () => {
        const firstPart =
            locale.isSeoDomain && locale.domainAreaId !== BELARUS_DOMAIN_AREA_ID
                ? trls[TrlKeys.titleIn]
                : trls[TrlKeys.titleTwo];

        return format(trls[TrlKeys.titleMain], {
            '{0}': firstPart,
            '{1}': locale.domainAreaId !== BELARUS_DOMAIN_AREA_ID ? companiesOfTheDayTrls.area : '',
        });
    };

    const getHeader = () => {
        if (isMagritte) {
            return (
                <Link to="/employers_company" data-qa="index__work-in-company-header">
                    <Title Element="h3" size="medium">
                        {getHeaderText()}
                    </Title>
                </Link>
            );
        }

        return (
            <>
                <H2>
                    <BlokoLink
                        Element={Link}
                        to="/employers_company"
                        data-qa="index__work-in-company-header"
                        kind={LinkKind.Tertiary}
                    >
                        {getHeaderText()}
                    </BlokoLink>
                </H2>
                <VSpacing base={4} />
            </>
        );
    };

    const getCompanyItem = (
        company: CompanyOfTheDay,
        { isAdditional = false, className }: { isAdditional?: boolean; className?: string } = {}
    ) => {
        if (isMagritte) {
            return (
                <div className={className} key={company.id}>
                    <CompanyItem company={company} />
                </div>
            );
        }
        return (
            <li
                className={classnames('work-in-company-list__item', {
                    'work-in-company-list__item_additional': isAdditional,
                })}
                key={company.id}
            >
                <div className="multiple-column-list-item multiple-column-list-item_complex">
                    <span className="multiple-column-list-item__text">
                        <Text Element="span">
                            <BlokoLink
                                Element={Link}
                                to={getCompanyLink(company, locale, WorkInCompanyLocation.MainPageBottom, isZP)}
                                kind={LinkKind.Tertiary}
                                onClick={() => sendAnalytics(company, 'employer_of_the_day')}
                            >
                                <span
                                    data-qa="company-of-the-day-name"
                                    ref={(ref) => {
                                        if (ref && companiesRefs.current) {
                                            companiesRefs.current[company.id] = ref;
                                        }
                                    }}
                                >
                                    {company.companyName}
                                </span>
                            </BlokoLink>
                        </Text>
                    </span>
                    <span className="multiple-column-list-item__count">
                        <Text Element="span" size={TextSize.Small} importance={TextImportance.Tertiary}>
                            {company.vacanciesNumber}
                        </Text>
                    </span>
                </div>
            </li>
        );
    };

    const getBannerColumn = () => {
        return (
            <div className={styles.bannerColumn}>
                {isWorkInCompanyBannerExist && (
                    <div className={styles.bannerWrapper}>
                        <WorkInBanner />
                    </div>
                )}
                {companiesOfTheDay
                    .slice(0, companiesListSliceIndex)
                    .map((company) => getCompanyItem(company, { className: styles.item }))}
            </div>
        );
    };

    if (isMagritte) {
        let previewCount =
            companiesPreviewCountForBreakpoint[breakpoint as keyof typeof companiesPreviewCountForBreakpoint] ||
            COMPANIES_PREVIEW_DEFAULT_COUNT;

        if (!isXS && isWorkInCompanyBannerExist) {
            previewCount -= 2;
        }
        return (
            <>
                {getHeader()}
                <MagritteVSpacing default={16} gteS={20} gteM={24} />
                <IndexCollapsibleBlock
                    renderPreview={() => (
                        <div className={styles.container}>
                            {!isXS && getBannerColumn()}
                            <div className={styles.wrapper}>
                                {companiesOfTheDay
                                    .slice(companiesListSliceIndex, previewCount)
                                    .map((company) => getCompanyItem(company, { className: styles.item }))}
                            </div>
                        </div>
                    )}
                    renderRest={() => (
                        <div className={styles.wrapperRest}>
                            {companiesOfTheDay
                                .slice(previewCount)
                                .map((company) => getCompanyItem(company, { className: styles.item }))}
                        </div>
                    )}
                    restSpacing={<MagritteVSpacing default={24} />}
                    needToExpand={companiesOfTheDay.length > previewCount}
                    renderBottomSheetContent={() => (
                        <div className={styles.wrapperVertical}>
                            <div className={styles.bannerWrapper}>
                                <WorkInBanner />
                            </div>
                            {companiesOfTheDay.map((company) => getCompanyItem(company))}
                        </div>
                    )}
                    bottomSheetTitle={getHeaderText()}
                />
                {spacingBottom}
            </>
        );
    }

    if (isApplicant) {
        return (
            <>
                <Column xs="4" s="8" m="4" l="16">
                    <div className="work-in-company-applicant">{getHeader()}</div>
                </Column>
                <Column xs="4" s="4" m="4" l="4">
                    <div className="work-in-company-applicant">
                        <WorkInBanner />
                        <Column xs="4" s="4" m="4" l="0" container>
                            <Gap top>
                                <ul className="multiple-column-list multiple-column-list_medium-1 multiple-column-list_small-1">
                                    {companiesOfTheDay
                                        .slice(0, PartIndex.One)
                                        .map((company) => getCompanyItem(company))}
                                </ul>
                            </Gap>
                        </Column>
                    </div>
                </Column>
                <Column xs="4" s="4" m="4" l="12" container>
                    <Column xs="4" s="0" m="0" l="12">
                        <ul className="multiple-column-list multiple-column-list_medium-1 multiple-column-list_small-1">
                            {companiesOfTheDay.slice(0, PartIndex.One).map((company) => getCompanyItem(company))}
                        </ul>
                    </Column>
                    <Column xs="4" s="4" m="4" l="12">
                        <div className="work-in-company-applicant">
                            <div className="work-in-company-list work-in-company-list_applicant">
                                <ul className="multiple-column-list multiple-column-list_medium-1 multiple-column-list_small-1">
                                    {companiesOfTheDay
                                        .slice(PartIndex.One, PartIndex.Two)
                                        .map((company) => getCompanyItem(company))}
                                    {companiesOfTheDay
                                        .slice(PartIndex.Two, PartIndex.Three)
                                        .map((company) => getCompanyItem(company, { isAdditional: true }))}
                                </ul>
                            </div>
                        </div>
                    </Column>
                </Column>
            </>
        );
    }

    return (
        <>
            <Column xs="4" s="4" m="8" l="4">
                <div className="work-in-company">{getHeader()}</div>
            </Column>
            <Column xs="4" s="4" m="4" l="4">
                <div className="work-in-company">
                    <Gap sBottom mBottom>
                        <WorkInBanner />
                    </Gap>
                    <Gap top mBottom>
                        <ul className="multiple-column-list multiple-column-list_medium-1 multiple-column-list_small-1">
                            {companiesOfTheDay.slice(0, PartIndex.One).map((company) => getCompanyItem(company))}
                        </ul>
                    </Gap>
                </div>
            </Column>
            <Column xs="4" s="4" m="4" l="4">
                <div className="work-in-company">
                    <div className="work-in-company-list">
                        <ul className="multiple-column-list multiple-column-list_medium-1 multiple-column-list_small-1 multiple-column-list_not-applicant">
                            {companiesOfTheDay
                                .slice(PartIndex.One, PartIndex.Two)
                                .map((company) => getCompanyItem(company))}
                        </ul>
                    </div>
                </div>
            </Column>
        </>
    );
};

export default translation(WorkInCompany);
